import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Sidebar from '../components/Sidebar'
import Layout from '../components/Layout'

class Success extends React.Component {
  render() {
    const { title } = this.props.data.site.siteMetadata

    return (
      <Layout>
        <div>
        <Helmet>
            <title>{`Form submitted | ${title}`}</title>
          </Helmet>
          <Sidebar {...this.props} />
          <div className="content">
            <div className="content__inner">
              <div className="page">
                <h1 className="page__title">Form submitted</h1>
                <div className="page__body">
                  <p>
                    Thanks for your message!
                  </p>
                  <p>
                    I'll do my best to get back to you as soon as I can. 
                    If your query is urgent, please try contacting me 
                    on twitter as <a href="http://twitter.com/chrisgrice">@chrisgrice</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Success

export const pageQuery = graphql`
  query SuccessQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
          email
          twitter
          github
        }
      }
    }
  }
`
